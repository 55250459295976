.height-xxxxs {
  height: var(--space-xxxxs);
}
.height-xxxs {
  height: var(--space-xxxs);
}
.height-xxs {
  height: var(--space-xxs);
}
.height-xs {
  height: var(--space-xs);
}
.height-sm {
  height: var(--space-sm);
}
.height-md {
  height: var(--space-md);
}
.height-lg {
  height: var(--space-lg);
}
.height-xl {
  height: var(--space-xl);
}
.height-xxl {
  height: var(--space-xxl);
}
.height-xxxl {
  height: var(--space-xxxl);
}
.height-xxxl-2x {
  height: calc(var(--space-xxxl) * 2);
}
.height-xxxxl {
  height: var(--space-xxxxl);
}

.width-xxxxs {
  width: var(--space-xxxxs);
}
.width-xxxs {
  width: var(--space-xxxs);
}
.width-xxs {
  width: var(--space-xxs);
}
.width-xs {
  width: var(--space-xs);
}
.width-sm {
  width: var(--space-sm);
}
.width-md {
  width: var(--space-md);
}
.width-lg {
  width: var(--space-lg);
}
.width-xl {
  width: var(--space-xl);
}
.width-xxl {
  width: var(--space-xxl);
}
.width-xxxl {
  width: var(--space-xxxl);
}
.width-xxxxl {
  width: var(--space-xxxxl);
}
.width-xxxl-2x {
  width: calc(var(--space-xxxl) * 2);
}
.width-xxxxl-2x {
  width: calc(var(--space-xxxxl) * 2);
}

.width-10 {
  width: 10%;
}
.width-20 {
  width: 20%;
}
.width-30 {
  width: 30%;
}
.width-40 {
  width: 40%;
}
.width-50 {
  width: 50%;
}
.width-48 {
  width: 48%;
}
.width-60 {
  width: 60%;
}
.width-70 {
  width: 70%;
}
.width-80 {
  width: 80%;
}
.width-90 {
  width: 90%;
}
.width-100 {
  width: 100%;
}

.height-10 {
  height: 10%;
}
.height-20 {
  height: 20%;
}
.height-30 {
  height: 30%;
}
.height-40 {
  height: 40%;
}
.height-50 {
  height: 50%;
}
.height-60 {
  height: 60%;
}
.height-70 {
  height: 70%;
}
.height-80 {
  height: 80%;
}
.height-90 {
  height: 90%;
}
.height-100 {
  height: 100%;
}

.height-100vh {
  height: 100vh;
}
