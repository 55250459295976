$breakpoints: (
  xs: 32rem,
  // ~512px
  sm: 48rem,
  // ~768px
  md: 64rem,
  // ~1024px
  lg: 80rem,
  // ~1280px
  xl: 90rem // ~1440px
) !default;

// ToDo: fix in later angular versions
// @mixin media-breakpoint-up($breakpointkeys...) {
//   @each $breakpoint, $val in $breakpoints {
//     @media screen and (max-width: #{$val}) {
//       @content ($breakpoint);
//     }
//   }
// }

// @mixin media-breakpoint-down($breakpointkeys...) {
//   @each $breakpoint, $val in $breakpoints {
//     @media screen and (min-width: #{$val}) {
//       @content ($breakpoint);
//     }
//   }
// }

// @include media-breakpoint-up(xs, sm, md, lg, xl) using ($breakpoint) {
//   .hidden-#{$breakpoint} {
//     display: none !important;
//   }
// }

// @include media-breakpoint-down(xs, sm, md, lg, xl) using ($breakpoint) {
//   .visible-#{$breakpoint} {
//     display: none !important;
//   }
// }

@media screen and (max-width: 32rem) {
  .hidden-xs {
    display: none !important;
  }
}
@media screen and (max-width: 48rem) {
  .hidden-sm {
    display: none !important;
  }
}
@media screen and (max-width: 64rem) {
  .hidden-md {
    display: none !important;
  }
}
@media screen and (max-width: 80rem) {
  .hidden-lg {
    display: none !important;
  }
}
@media screen and (max-width: 90rem) {
  .hidden-xl {
    display: none !important;
  }
}
@media screen and (min-width: 32rem) {
  .visible-xs {
    display: none !important;
  }
}
@media screen and (min-width: 48rem) {
  .visible-sm {
    display: none !important;
  }
}
@media screen and (min-width: 64rem) {
  .visible-md {
    display: none !important;
  }
}
@media screen and (min-width: 80rem) {
  .visible-lg {
    display: none !important;
  }
}
@media screen and (min-width: 90rem) {
  .visible-xl {
    display: none !important;
  }
}
