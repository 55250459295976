div.content-default-padding {
  padding: var(--space-sm);
}

/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
div.mat-dialog-base {
  display: flex;
  flex-direction: column;
}

.profile-picture-container {
  border-radius: 5em;
  width: 10em;
  height: 10em;
}

// detailed expansion table or card
.detailed-header {
  font-size: 1em;
}

.detailed-panel {
  box-shadow:
    0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  margin: 10px;

  &.px100 {
    width: 100px;
    height: 100px;
  }
}

.floating-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  border-radius: 8px 8px 0 0;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
  z-index: 2;

  &.desktop {
    left: 235px;
  }
}

button.table-icon {
  padding: 0 !important;
  min-width: 0;
  height: 20px !important;
  margin-bottom: 3px;
  color: rgb(95, 95, 95) !important;

  .mat-icon {
    margin-right: 0;
  }
}

.floating-site-parent {
  position: relative;
}

.floating-site-route {
  position: absolute;
  top: 50%;
  right: var(--space-xs);
  transform: translateY(-50%);
}
