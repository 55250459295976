.marker-label {
  margin-left: 24px;
}

.site-marker {
  width: 48px;
  height: 48px;
  position: relative; // Make the parent a positioning context
}

.site-inner-div {
  position: absolute;
  left: 50%;
  bottom: 0;
}
