.bg-white {
  background: white;
}

.bg-black {
  background: black;
}

.bg-muted {
  background: var(--color-divider);
}

.bg-primary {
  background: var(--bg-primary);
  color: white;
}

.bg-accent {
  background: var(--bg-accent);
  color: white;
}

.bg-disabled {
  background: rgba(0, 0, 0, 0.38);
}

// for dashboard widget icon background
.mat-mdc-card.mat-card-blue .bg-accent {
  background: var(--bg-accent) !important;
  color: white !important;
}

.bg-error {
  background: var(--bg-error);
  color: white;
}

.mat-mdc-snack-bar-container {
  &.bg-success {
    border-radius: 4px;
    --mdc-snackbar-container-color: var(--notify-success);
    --mat-mdc-snack-bar-button-color: black;
    --mdc-snackbar-supporting-text-color: black;
  }
  &.bg-error-85 {
    border-radius: 4px;
    --mdc-snackbar-container-color: var(--notify-error);
    --mat-mdc-snack-bar-button-color: white;
    --mdc-snackbar-supporting-text-color: white;
  }
  &.bg-notification {
    border-radius: 4px;
    --mdc-snackbar-container-color: var(--notify-info);
    --mat-mdc-snack-bar-button-color: black;
    --mdc-snackbar-supporting-text-color: black;
  }
}
.bg-success {
  background: var(--notify-success);
  color: black;
}

.bg-warn-85 {
  background: var(--notify-warn);
}

.bg-error-85 {
  background: var(--notify-error);
  color: white;
}

.bg-notification {
  background: var(--notify-info);
  color: black;
}

.bg-table-success {
  background: var(--table-success);
}

.bg-table-warn {
  background: var(--table-warn);
}

.bg-table-error {
  background: var(--table-error);
}

.bg-table-notification {
  background: var(--table-info);
}

.text-success {
  color: var(--notify-success);
}
.text-muted {
  color: #888;
}
.text-primary {
  color: var(--primary-color);
}
.text-info {
  color: var(--notify-info);
}
.text-warn {
  color: var(--notify-warn);
}
.text-error {
  color: var(--notify-error);
}
