:root {
  // font size
  --text-base-size: 1rem; // body font-size
  --text-unit: 1rem;
  --text-scale-ratio: 1.17; // multiplier used to generate the scale values 👇
}

:root,
* {
  // type scale
  --text-xs: calc((var(--text-unit) / var(--text-scale-ratio)) / var(--text-scale-ratio));
  --text-sm: calc(var(--text-xs) * var(--text-scale-ratio));
  --text-md: calc(var(--text-sm) * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-lg: calc(var(--text-md) * var(--text-scale-ratio));
  --text-xl: calc(var(--text-lg) * var(--text-scale-ratio));
  --text-xxl: calc(var(--text-xl) * var(--text-scale-ratio));
  --text-xxxl: calc(var(--text-xxl) * var(--text-scale-ratio));
  --text-xxxxl: calc(var(--text-xxxl) * var(--text-scale-ratio));
}

.text-xs {
  font-size: var(--text-xs);
}
.text-sm {
  font-size: var(--text-sm);
}
.text-base {
  font-size: var(--text-unit, 1em);
}
.text-md {
  font-size: var(--text-md);
}
.text-lg {
  font-size: var(--text-lg);
}
.text-xl {
  font-size: var(--text-xl);
}
.text-xxl {
  font-size: var(--text-xxl);
}
.text-xxxl {
  font-size: var(--text-xxxl);
}
.text-xxxxl {
  font-size: var(--text-xxxxl);
}

// font weight
.font-light {
  font-weight: 300;
}
.font-normal {
  font-weight: 400;
}
.font-medium {
  font-weight: 500;
}
.font-semibold {
  font-weight: 600;
}
.font-bold {
  font-weight: 700;
}

// text color
.text-color-success {
  color: var(--text-success);
}
.text-color-error {
  color: var(--text-error);
}
.text-color-warn {
  color: var(--text-warn);
}
.text-color-info {
  color: var(--text-info);
}

.text-underline {
  text-decoration: underline;
}
