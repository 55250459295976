@use '@angular/material' as mat;

/* You can add global styles to this file, and also import other style files */
@import 'styles/reset.scss';

/* custom component styles */
@import 'styles/components/colors.scss';
@import 'styles/components/utility-classes.scss';
@import 'styles/components/media.scss';
@import 'styles/components/sizes.scss';
@import 'styles/components/spaces.scss';
@import 'styles/components/divider.scss';
@import 'styles/components/material-misc.scss';
@import 'styles/components/misc.scss';
@import 'styles/components/text.scss';
@import 'styles/components/location.scss';
@import 'styles/components/google-maps.scss';

@include mat.form-field-density(-1);

// ToDo: move all this into a component and import if possible
.fit-right {
  margin-right: -50px;
}
@media screen and (max-width: 576px) {
  .fit-right {
    margin-right: 0;
  }
}

app-root {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  top: 64px;
  right: 0;
}
.hidden {
  display: none;
}
@media screen and (max-width: 768px) {
  app-root {
    top: 56px;
  }
}

.main-menu .mat-expansion-panel-body {
  padding-left: 0;
  padding-right: 0;
}

.clickable {
  cursor: pointer;
}

.container-small {
  width: 100%;
  max-width: 786px;
}

/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
.justify-center .mat-chip-list-wrapper {
  justify-content: center;
}

.drawer-fullpage {
  height: 100%;
}
.drawer-fullpage .mat-drawer-content {
  max-width: 100vw;
}

.chip-small {
  font-size: 0.8em !important;
  padding: 5px 8px !important;
  min-height: 27px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
mat-card-content.alert {
  margin: 0 -16px;
  padding: 16px;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
mat-card-content.alert.alert-info {
  background: var(--primary-lighter-color);
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.table-toolbar-top {
  align-items: baseline;
  flex-wrap: wrap-reverse;
}
.table-toolbar-bottom {
  flex-wrap: wrap;
}
