.main-layout {
  height: 100%;
  max-width: 95rem;
  margin-left: auto;
  margin-right: auto;
}

.overlay-container {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  z-index: 1;
}

.dynamic-color {
  background-color: #4285f4;
}
