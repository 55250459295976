.flex {
  display: flex;
  column-gap: var(--space-xs, 1rem);

  &.wrap {
    flex-wrap: wrap;
  }
  &.inline {
    display: inline-flex;
  }
  &.space-between {
    justify-content: space-between;
  }
  &.justify-center {
    justify-content: center;
  }
  &.space-around {
    justify-content: space-around;
  }
  &.justify-stretch {
    justify-content: stretch;
  }
  &.justify-end {
    justify-content: end;
  }
  &.justify-start {
    justify-content: flex-start;
  }
  &.align-center {
    align-items: center;
  }
  &.align-start {
    align-items: start;
  }
  &.align-end {
    align-items: end;
  }
  &.align-flex-end {
    align-items: flex-end;
  }
  &.column {
    flex-direction: column;
    row-gap: var(--space-xs);
  }
  &.no-gap {
    gap: 0;
  }
  &.flex-gap-xs {
    gap: var(--space-xs);
  }
  &.flex-gap-xl {
    gap: var(--space-xl);
  }
  &.flex-gap-sm {
    gap: 0.75rem;
  }
}

.flex-grow-1 {
  flex-grow: 1;
}
.flex-grow-0 {
  flex-grow: 0;
}

.flex-width {
  width: 100%;
}

.grid {
  display: grid;
  gap: var(--space-xs, 1rem);

  &.align-center {
    align-items: center;
  }
  &.two-columns {
    grid-template-columns: 1fr 1fr;
  }

  &.three-columns {
    grid-template-columns: 1fr 1fr 1fr;
  }

  &.no-gap {
    gap: unset;
  }
  &.gap-xxxs {
    gap: var(--space-xxxs);
  }
  &.gap-xs {
    gap: var(--space-xs);
  }
}

.display-block {
  display: block;
}

//  ToDo change to .container when mdb is removed
.container-new {
  padding-inline: 2em;
  margin-inline: auto;
  max-width: 80%;
}

.radius-5px {
  border-radius: 5px;
}

.radius-4px {
  border-radius: 4px;
}

.pull-right {
  float: right;
}

// Spacing helpers taken from bulma (https://github.com/jgthms/bulma/blob/master/sass/helpers/spacing.sass)
$spacing-shortcuts: (
  'margin': 'm',
  'padding': 'p'
) !default;
$spacing-directions: (
  'top': 't',
  'right': 'r',
  'bottom': 'b',
  'left': 'l'
) !default;
$spacing-horizontal: 'x' !default;
$spacing-vertical: 'y' !default;
$spacing-values: (
  '0': 0,
  '1': 0.25rem,
  '2': 0.5rem,
  '3': 0.75rem,
  '4': 1rem,
  '5': 1.5rem,
  '6': 3rem,
  'auto': auto
) !default;

@each $property, $shortcut in $spacing-shortcuts {
  @each $name, $value in $spacing-values {
    // All directions
    .#{$shortcut}-#{$name} {
      #{$property}: $value !important;
    }
    // Cardinal directions
    @each $direction, $suffix in $spacing-directions {
      .#{$shortcut}#{$suffix}-#{$name} {
        #{$property}-#{$direction}: $value !important;
      }
    }
    // Horizontal axis
    @if $spacing-horizontal != null {
      .#{$shortcut}#{$spacing-horizontal}-#{$name} {
        #{$property}-left: $value !important;
        #{$property}-right: $value !important;
      }
    }
    // Vertical axis
    @if $spacing-vertical != null {
      .#{$shortcut}#{$spacing-vertical}-#{$name} {
        #{$property}-top: $value !important;
        #{$property}-bottom: $value !important;
      }
    }
  }
}
