@use '@angular/material' as mat;
@use '@ng-matero/extensions' as mtx;

// adjust this to your color theme, the material theme will use this variables to create the theme
// primary parts to make it darker or lighter
$primary-h: 211;
$primary-s: 57%;
$primary-l: 38%;
// accent parts
$accent-h: 49;
$accent-s: 86%;
$accent-l: 61%;
// error parts
$error-h: 15;
$error-s: 100%;
$error-l: 52%;
// end adjust

$mat-primary: (
  50: hsl($primary-h $primary-s calc($primary-l + 50%)),
  100: hsl($primary-h $primary-s calc($primary-l + 40%)),
  200: hsl($primary-h $primary-s calc($primary-l + 30%)),
  300: hsl($primary-h $primary-s calc($primary-l + 20%)),
  400: hsl($primary-h $primary-s calc($primary-l + 10%)),
  500: hsl($primary-h $primary-s $primary-l),
  600: hsl($primary-h $primary-s calc($primary-l - 10%)),
  700: hsl($primary-h $primary-s calc($primary-l - 15%)),
  800: hsl($primary-h $primary-s calc($primary-l - 20%)),
  900: hsl($primary-h $primary-s calc($primary-l - 25%)),
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white
  )
);

$mat-accent: (
  50: hsl($accent-h $accent-s calc($accent-l + 50%)),
  100: hsl($accent-h $accent-s calc($accent-l + 40%)),
  200: hsl($accent-h $accent-s calc($accent-l + 30%)),
  300: hsl($accent-h $accent-s calc($accent-l + 20%)),
  400: hsl($accent-h $accent-s calc($accent-l + 10%)),
  500: hsl($accent-h $accent-s $accent-l),
  600: hsl($accent-h $accent-s calc($accent-l - 10%)),
  700: hsl($accent-h $accent-s calc($accent-l - 15%)),
  800: hsl($accent-h $accent-s calc($accent-l - 20%)),
  900: hsl($accent-h $accent-s calc($accent-l - 25%)),
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(black, 0.87),
    600: rgba(black, 0.87),
    700: rgba(black, 0.87),
    800: white,
    900: white
  )
);

$mat-warn: (
  50: hsl($error-h $error-s calc($error-l + 50%)),
  100: hsl($error-h $error-s calc($error-l + 40%)),
  200: hsl($error-h $error-s calc($error-l + 30%)),
  300: hsl($error-h $error-s calc($error-l + 20%)),
  400: hsl($error-h $error-s calc($error-l + 10%)),
  500: hsl($error-h $error-s $error-l),
  600: hsl($error-h $error-s calc($error-l - 10%)),
  700: hsl($error-h $error-s calc($error-l - 15%)),
  800: hsl($error-h $error-s calc($error-l - 20%)),
  900: hsl($error-h $error-s calc($error-l - 25%)),
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white
  )
);

@include mat.all-component-typographies();
@include mat.core();

$my-primary: mat.define-palette($mat-primary, 500);
$my-accent: mat.define-palette($mat-accent, 500, 900);
$my-warn: mat.define-palette($mat-warn, 500, 900);

$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
      warn: $my-warn
    )
  )
);

@include mat.all-component-themes($my-theme);
@include mtx.all-component-themes($my-theme);

:root {
  --primary-h: #{$primary-h};
  --primary-s: #{$primary-s};
  --primary-l: #{$primary-l};

  --accent-h: #{$accent-h};
  --accent-s: #{$accent-s};
  --accent-l: #{$accent-l};

  --error-h: #{$error-h};
  --error-s: #{$error-s};
  --error-l: #{$error-l};

  // -no-change-- usually dont change unless primary or accent collides
  // success parts
  --success-h: 158;
  --success-s: 54%;
  --success-l: 54%;
  // warn parts
  --warn-h: 45;
  --warn-s: 100%;
  --warn-l: 51.37%;

  --clr-divider: 200 18% 46%; // #607d8b set alpha in usage of variable
  --clr-no-status: 217 4% 37%;
  // -end-no-change--

  --accent-hsl: var(--accent-h) var(--accent-s) var(--accent-l);
  --primary-hsl: var(--primary-h) var(--primary-s) var(--primary-l);
  --primary-hsl-light: var(--primary-h) var(--primary-s) calc(var(--primary-l) + 10%);
  --clr-error: var(--error-h) var(--error-s) var(--error-l);
  --clr-success: var(--success-h) var(--success-s) var(--success-l);
  --clr-warn: var(--warn-h) var(--warn-s) var(--warn-l);
  --clr-info: 209 88% 70%;

  --accent-color: hsl(var(--accent-hsl));
  --primary-color: hsl(var(--primary-hsl));

  --color-divider: hsl(var(--clr-divider) / 0.2);

  --notify-no-status: hsl(var(--clr-no-status) / 0.85);
  --notify-success: hsl(var(--clr-success) / 0.85);
  --notify-error: hsl(var(--clr-error) / 0.85);
  --notify-warn: hsl(var(--clr-warn) / 0.85);
  --notify-info: hsl(var(--clr-info) / 0.85);

  --text-success: hsl(var(--clr-success));
  --text-error: hsl(var(--clr-error));
  --text-warn: hsl(var(--clr-warn));
  --text-info: hsl(var(--clr-info));

  --table-decent-accent: #eceff1;
  --table-accent: #cfd8dc;
  --table-text-muted: hsl(var(--clr-divider));
  --table-success: hsl(var(--clr-success) / 0.5);
  --table-error: hsl(var(--clr-error) / 0.5);
  --table-warn: hsl(var(--clr-warn) / 0.5);
  --table-info: hsl(var(--clr-info) / 0.5);
  --table-no-status: hsl(var(--clr-no-status) / 0.3);

  --bg-color: #fff;
  --bg-dark-color: #000;
  --bg-primary: hsl(var(--primary-hsl) / 1);
  --bg-primary-light: hsl(var(--primary-hsl-light) / 0.9);
  --bg-accent: hsl(var(--accent-hsl) / 1);
  --bg-error: hsl(var(--clr-error) / 1);

  --space-unit: 2rem;
  --space-xxxxs: calc(0.09 * var(--space-unit));
  --space-xxxs: calc(0.146 * var(--space-unit));
  --space-xxs: calc(0.236 * var(--space-unit));
  --space-xs: calc(0.382 * var(--space-unit));
  --space-sm: calc(0.618 * var(--space-unit));
  --space-md: calc(1 * var(--space-unit));
  --space-lg: calc(1.618 * var(--space-unit));
  --space-xl: calc(2.618 * var(--space-unit));
  --space-xxl: calc(4.236 * var(--space-unit));
  --space-xxxl: calc(6.854 * var(--space-unit));
  --space-xxxxl: calc(11.08 * var(--space-unit));
  --component-padding: var(--space-sm);
}
