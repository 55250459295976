// --- xxxs ---
.margin-xxxs-bottom {
  margin-bottom: var(--space-xxxs);
}
.margin-xxxs-top {
  margin-top: var(--space-xxxs);
}

// --- xxs ---
.space-xxs-left {
  padding-left: var(--space-xxs);
}
.space-xxs-right {
  padding-right: var(--space-xxs);
}
.space-xxs-bottom {
  padding-bottom: var(--space-xxs);
}
.space-xxs-top {
  padding-top: var(--space-xxs);
}

.margin-xxs-right {
  margin-right: var(--space-xxs);
}
.margin-xxs-bottom {
  margin-bottom: var(--space-xxs);
}
.margin-xxs-left {
  margin-left: var(--space-xxs);
}
.margin-xxs-top {
  margin-top: var(--space-xxs);
}

// --- xs ---
.space-xs-left {
  padding-left: var(--space-xs);
}
.space-xs-right {
  padding-right: var(--space-xs);
}
.space-xs-bottom {
  padding-bottom: var(--space-xs);
}
.space-xs-top {
  padding-top: var(--space-xs);
}

.margin-xs-bottom {
  margin-bottom: var(--space-xs);
}
.margin-xs-top {
  margin-top: var(--space-xs);
}
.margin-xs-right {
  margin-right: var(--space-xs);
}
.margin-xs-left {
  margin-left: var(--space-xs);
}

// --- sm ---
.space-sm-left {
  padding-left: var(--space-sm);
}
.space-sm-right {
  padding-right: var(--space-sm);
}
.space-sm-top {
  margin-top: var(--space-sm);
}
.space-sm-bottom {
  margin-bottom: var(--space-sm);
}

.margin-sm {
  margin: var(--space-sm);
}
.margin-sm-bottom {
  margin-bottom: var(--space-sm);
}

// --- md ---
.space-md-bottom {
  padding-bottom: var(--space-md);
}
.space-md-top {
  padding-top: var(--space-md);
}
.space-md-left {
  padding-left: var(--space-md);
}
.space-md-right {
  padding-right: var(--space-md);
}

.margin-md-top {
  margin-top: var(--space-md);
}
.margin-md-bottom {
  margin-bottom: var(--space-md);
}
.margin-md-left {
  margin-left: var(--space-md);
}
.margin-md-right {
  margin-right: var(--space-md);
}

// --- full padding ---
.gap-xxxxs {
  padding: var(--space-xxxxs);
}
.gap-xxxs {
  padding: var(--space-xxxs);
}
.gap-xxs {
  padding: var(--space-xxs);
}
.gap-xs {
  padding: var(--space-xs);
}
.gap-sm {
  padding: var(--space-sm);
}
.gap-md {
  padding: var(--space-md);
}
.gap-lg {
  padding: var(--space-lg);
}
.gap-xl {
  padding: var(--space-xl);
}
.gap-xxl {
  padding: var(--space-xxl);
}
.gap-xxxl {
  padding: var(--space-xxxl);
}
.gap-xxxxl {
  padding: var(--space-xxxxl);
}
.gap-0 {
  padding: 0;
}

.no-margin {
  margin: unset;
}

// full margin
.margin-xs {
  margin: var(--space-xs);
}
