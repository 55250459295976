// mat-card
/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
mat-card-header.color-primary {
  color: var(--primary-color);
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
mat-card.padding-floating-menu {
  padding-bottom: 66px;
}

// overwrite bootstrap to center textbox labels
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-layout {
  margin-bottom: unset;
  white-space: unset !important;
}

// mat tooltip as popover, soaplog table
.mat-mdc-tooltip.status-popover {
  border: var(--table-text-muted) 1px solid;
  background: var(--bg-color);
  color: var(--bg-dark-color);
  font-size: var(--text-sm);
  max-width: 300px;
}

// Remove bottom padding (for subscript text) from form fields with the `no-subscript` class
.no-subscript .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

// for fullscreen modal, stick action bar to bottom
/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
.mat-dialog-override {
  /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
  mat-dialog-container {
    > :first-child {
      display: flex !important;
      flex-direction: column;
      height: 100%;
    }
    mat-dialog-content,
    div[mat-dialog-content] {
      flex-grow: 1;
      max-height: unset;
    }
  }
}

.mat-mdc-slider .mdc-slider__value-indicator::before {
  border-top-color: var(--accent-color) !important;
}
.mat-mdc-slider .mdc-slider__value-indicator {
  background-color: var(--accent-color) !important;
  opacity: 1 !important;
}
.mdc-slider .mdc-slider__value-indicator {
  transform: none !important;
}

// form field
.mat-mdc-form-field {
  width: 100%;
  min-width: auto;
}

.form-field-small {
  height: 35px;

  &.width-55px {
    width: 55px;
  }

  &.width-100px {
    width: 100px;
  }

  &.width-125px {
    width: 125px;
  }
}
.form-field-small .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: unset;
  padding-left: 6px;
  padding-bottom: unset;
}
.form-field-small .mat-mdc-form-field-flex {
  height: 35px;
}
.form-field-small .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 18px;
}
.form-field-small .mdc-text-field--filled .mdc-floating-label--float-above {
  top: 23px !important;
}
.form-field-small .mat-mdc-form-field-infix {
  min-height: 35px;
}
.form-field-small .mat-date-range-input-container {
  padding-top: 15px;
}
.form-field-small
  .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea)
  .mat-mdc-form-field-infix {
  padding: 6px;
}
.form-field-small .mdc-text-field {
  padding: unset;
}

.auto-width .mat-mdc-form-field-infix {
  width: auto;
}

// /**
// colored mat cards for dashboard
// */
$customColors: (
  'green': (
    'main': hsl(var(--success-h), var(--success-s), var(--success-l)),
    'dark': hsl(var(--success-h), var(--success-s), calc(var(--success-l) - 20%)),
    'light': hsl(var(--success-h), var(--success-s), calc(var(--success-l) + 40%))
  ),
  'red': (
    'main': hsl(var(--error-h), var(--error-s), var(--error-l)),
    'dark': hsl(var(--error-h), var(--error-s), calc(var(--error-l) - 20%)),
    'light': hsl(var(--error-h), var(--error-s), calc(var(--error-l) + 40%))
  ),
  'dark': (
    'main': #737373,
    'dark': #969696,
    'light': #e0e0e0
  ),
  // ToDo: thats actually primary
  'blue':
    (
      'main': hsl(var(--primary-h), var(--primary-s), var(--primary-l)),
      'dark': hsl(var(--primary-h), var(--primary-s), calc(var(--primary-l) - 20%)),
      'light': hsl(var(--primary-h), var(--primary-s), calc(var(--primary-l) + 40%))
    ),
  'yellow': (
    'main': hsl(var(--warn-h), var(--warn-s), var(--warn-l)),
    'dark': hsl(var(--warn-h), var(--warn-s), calc(var(--warn-l) - 20%)),
    'light': hsl(var(--warn-h), var(--warn-s), calc(var(--warn-l) + 40%))
  )
);
@each $name, $data in $customColors {
  .mat-mdc-card.mat-card-#{$name},
  .mat-button-toggle-appearance-standard.mat-button-#{$name} {
    background: map-get($data, 'main');
    color: rgba(255, 255, 255, 0.9);
  }
  .mat-mdc-card.mat-card-#{$name} .mat-progress-bar-buffer {
    background: map-get($data, 'light');
  }
  .mat-mdc-card.mat-card-#{$name} .mat-progress-bar-fill::after,
  .mat-mdc-card.mat-card-#{$name} .widget-icon,
  .mat-button-toggle-appearance-standard.mat-button-#{$name}.mat-button-toggle-checked {
    background: map-get($data, 'dark');
  }
}

.mdc-list-item--with-leading-icon .mdc-list-item__start {
  margin-right: 16px !important;
}

td.mat-mdc-cell,
td.mat-mdc-footer-cell,
th.mat-mdc-header-cell {
  padding: 0;
  padding-left: 1em;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
td.mat-mdc-cell:first-of-type,
td.mat-mdc-footer-cell:first-of-type,
th.mat-mdc-header-cell:first-of-type {
  padding-left: 24px;
}
table.mat-mdc-table {
  width: 100%;
  border-spacing: 0;
}
tr.mat-mdc-footer-row,
tr.mat-mdc-row {
  height: 48px;
}

.mat-mdc-header-cell .sort-indicator {
  opacity: 0.1;
}
.mat-mdc-header-cell:hover .sort-indicator,
.mat-header-cell .sort-indicator.active {
  opacity: 1;
}
